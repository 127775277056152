// Remember to add any new pages to the other page constants below
// ALSO: Remember to add any new pages to the App.tsx router
export enum pageUrls {
  HOME = "/",
  ABOUT = "/about",
  PACKAGES = "/packages",
  SONGLIST = "/songlist",
  VIDEOS = "/videos",
  REVIEWS = "/reviews",
  PHOTOS = "/photos",
  CONTACT = "/contact",
  DYNAMICSONGLIST = "/songlist",
}

export enum pageTitles {
  HOME = "Home",
  ABOUT = "About",
  PACKAGES = "Packages",
  SONGLIST = "Song List",
  VIDEOS = "Videos",
  REVIEWS = "Reviews",
  PHOTOS = "Photos",
  CONTACT = "Contact",
  DYNAMICSONGLIST = "Song List",
}

export const pages = [
  {
    title: pageTitles.ABOUT,
    url: pageUrls.ABOUT,
  },
  { title: pageTitles.PACKAGES, url: pageUrls.PACKAGES },
  { title: pageTitles.PHOTOS, url: pageUrls.PHOTOS },
  { title: pageTitles.VIDEOS, url: pageUrls.VIDEOS },
  { title: pageTitles.REVIEWS, url: pageUrls.REVIEWS },
  { title: pageTitles.DYNAMICSONGLIST, url: pageUrls.DYNAMICSONGLIST },
  { title: pageTitles.CONTACT, url: pageUrls.CONTACT },
];

export enum decades {
  ALL_DECADES = "All decades",
  NINETEEN_FIFTIES = "1950s",
  NINETEEN_SIXTIES = "1960s",
  NINETEEN_SEVENTIES = "1970s",
  NINETEEN_EIGHTIES = "1980s",
  NINETEEN_NINETIES = "1990s",
  TWO_THOUSANDS = "2000s",
  TWO_THOUSAND_AND_TENS = "2010s",
  TWO_THOUSAND_AND_TWENTIES = "2020s",
}

export enum genres {
  ALL_GENRES = "All genres",
  ROCK = "Rock",
  POP = "Pop",
  COUNTRY = "Country",
  DISNEY = "Disney",
  FOLK = "Folk",
  CHRISTMAS = "Christmas",
}

export enum socialUrls {
  FACEBOOK = "https://www.facebook.com/samdawsonmusic",
  INSTAGRAM = "https://www.instagram.com/samdawsonmusic",
  YOUTUBE = "https://www.youtube.com/@sam-dawson-music",
  TIKTOK = "https://www.tiktok.com/@samdawsonmusic",
}
