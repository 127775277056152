import { Song } from "../appStateTypes";

export const songs: Song[] = [
  {
    title: "Islands In The Stream",
    artist: "Dolly Parton & Kenny Rogers",
    year: "1983",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Make You Feel My Love",
    artist: "Adele",
    year: "2008",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "If I Ain't Got You",
    artist: "Alicia Keys",
    year: "2003",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Best Day Of My Life",
    artist: "American Authors",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Valerie",
    artist: "Amy Winehouse",
    year: "2007",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "No Diggity",
    artist: "Blackstreet",
    year: "1996",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "I'll Name The Dogs",
    artist: "Blake Shelton",
    year: "2017",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Blowin' In The Wind",
    artist: "Bob Dylan",
    year: "1963",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Knockin' On Heaven's Door",
    artist: "Bob Dylan",
    year: "1973",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Marry You",
    artist: "Bruno Mars",
    year: "2010",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Ain't Nobody",
    artist: "Chaka Khan",
    year: "1983",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Johnny B. Goode",
    artist: "Chuck Berry",
    year: "1958",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "The Girl",
    artist: "City And Colour",
    year: "2008",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Yellow",
    artist: "Coldplay",
    year: "2000",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "The Blower's Daughter",
    artist: "Damien Rice",
    year: "2002",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Cannonball",
    artist: "Damien Rice",
    year: "2002",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "The Man Who Sold The World",
    artist: "David Bowie",
    year: "1970",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Babylon",
    artist: "David Gray",
    year: "1999",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "American Pie",
    artist: "Don McLean",
    year: "1971",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Perfect",
    artist: "Ed Sheeran",
    year: "2017",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Thinking Out Loud",
    artist: "Ed Sheeran",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "How Long Will I Love You",
    artist: "Ellie Goulding",
    year: "2013",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Can't Help Falling In Love",
    artist: "Elvis Presley",
    year: "1961",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "A Little Respect",
    artist: "Erasure",
    year: "1988",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Everlong",
    artist: "Foo Fighters",
    year: "1997",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Times Like These",
    artist: "Foo Fighters",
    year: "2002",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Blame It On Me",
    artist: "George Ezra",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Budapest",
    artist: "George Ezra",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Hold My Girl",
    artist: "George Ezra",
    year: "2018",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Shotgun",
    artist: "George Ezra",
    year: "2018",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Good Riddance (Time Of Your Life)",
    artist: "Green Day",
    year: "1997",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Sweet Child O' Mine",
    artist: "Guns N' Roses",
    year: "1987",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "You Make My Dreams",
    artist: "Hall & Oates",
    year: "1980",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Banana Pancakes",
    artist: "Jack Johnson",
    year: "2005",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Better Together",
    artist: "Jack Johnson",
    year: "2005",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Sitting, Waiting, Wishing",
    artist: "Jack Johnson",
    year: "2005",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Reet Petite",
    artist: "Jackie Wilson",
    year: "1957",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Sit Down",
    artist: "James",
    year: "1989",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "You've Got A Friend",
    artist: "James Taylor",
    year: "1971",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "I'm Yours",
    artist: "Jason Mraz",
    year: "2008",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Take Me Home, Country Roads",
    artist: "John Denver",
    year: "1971",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Imagine",
    artist: "John Lennon",
    year: "1971",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Dirt On My Boots",
    artist: "Jon Pardi",
    year: "2016",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Ring Of Fire",
    artist: "Johnny Cash",
    year: "1963",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Bare Necessities",
    artist: "Jungle Book",
    year: "1967",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Walking On Sunshine",
    artist: "Katrina & The Waves",
    year: "1983",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "I Kissed A Girl",
    artist: "Katy Perry",
    year: "2008",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "The Gambler",
    artist: "Kenny Rogers",
    year: "1978",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Use Somebody",
    artist: "King Of Leon",
    year: "2008",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Suddenly I See",
    artist: "KT Tunstall",
    year: "2004",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Hallelujah",
    artist: "Leonard Cohen",
    year: "1984",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "When It Rains It Pours",
    artist: "Luke Combs",
    year: "2017",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "It Must Be Love",
    artist: "Madness",
    year: "1981",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Moves Like Jagger",
    artist: "Maroon 5",
    year: "2011",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Ain't No Mountain High Enough",
    artist: "Marvin Gaye & Tammi Terrell",
    year: "1967",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "It's All About You",
    artist: "McFly",
    year: "2005",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "All About That Bass",
    artist: "Meghan Trainor",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Little Lion Man",
    artist: "Mumford & Sons",
    year: "2009",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Sweet Caroline",
    artist: "Neil Diamond",
    year: "1969",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "L.O.V.E",
    artist: "Nat King Cole",
    year: "1965",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Dream Catch Me",
    artist: "Newton Faulkner",
    year: "2007",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Champagne Supernova",
    artist: "Oasis",
    year: "1995",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Don't Look Back In Anger",
    artist: "Oasis",
    year: "1996",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Half The World Away",
    artist: "Oasis",
    year: "1994",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "She's Electric",
    artist: "Oasis",
    year: "1995",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Wonderwall",
    artist: "Oasis",
    year: "1995",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Wagon Wheel",
    artist: "Old Crow Medicine Show",
    year: "2004",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Last Request",
    artist: "Paolo Nutini",
    year: "2006",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "New Shoes",
    artist: "Paolo Nutini",
    year: "2006",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Wish You Were Here",
    artist: "Pink Floyd",
    year: "1975",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Hey There Delilah",
    artist: "Plain White T's",
    year: "2005",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Crazy Little Thing Called Love",
    artist: "Queen",
    year: "1979",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "You've Got A Friend In Me",
    artist: "Randy Newman",
    year: "1995",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Golden Touch",
    artist: "Razorlight",
    year: "2004",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Under The Bridge",
    artist: "Red Hot Chili Peppers",
    year: "1991",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Swing Life Away",
    artist: "Rise Against",
    year: "2004",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Angels",
    artist: "Robbie Williams",
    year: "1997",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Love You Like I Used To",
    artist: "Russell Dickerson",
    year: "2020",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Wonderful World",
    artist: "Sam Cooke",
    year: "1960",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Body Like A Back Road",
    artist: "Sam Hunt",
    year: "2017",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Cecilia",
    artist: "Simon & Garfunkel",
    year: "1970",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Mrs. Robinson",
    artist: "Simon & Garfunkel",
    year: "1968",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "The Sound Of Silence",
    artist: "Simon & Garfunkel",
    year: "1964",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Chasing Cars",
    artist: "Snow Patrol",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Rockin' All Over The World",
    artist: "Status Quo",
    year: "1977",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Stuck In The Middle With You",
    artist: "Stealers Wheel",
    year: "1972",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Dakota",
    artist: "Stereophonics",
    year: "2005",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Isn't She Lovely",
    artist: "Stevie Wonder",
    year: "1976",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Shake It Off",
    artist: "Taylor Swift",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Can't Buy Me Love",
    artist: "The Beatles",
    year: "1964",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Eight Days A Week",
    artist: "The Beatles",
    year: "1964",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Here Comes The Sun",
    artist: "The Beatles",
    year: "1969",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Hey Jude",
    artist: "The Beatles",
    year: "1968",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Yesterday",
    artist: "The Beatles",
    year: "1965",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Rotterdam",
    artist: "The Beautiful South",
    year: "1996",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Let Your Love Flow",
    artist: "The Bellamy Brothers",
    year: "1976",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Friday I'm In Love",
    artist: "The Cure",
    year: "1992",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Dream",
    artist: "The Everly Brothers",
    year: "1958",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Whistle For The Choir",
    artist: "The Fratellis",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "I Wanna Be Like You",
    artist: "The Jungle Book",
    year: "1967",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Mr. Brightside",
    artist: "The Killers",
    year: "2003",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Ooh La",
    artist: "The Kooks",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "She Moves In Her Own Way",
    artist: "The Kooks",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Ho Hey",
    artist: "The Lumineers",
    year: "2012",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "I'm A Believer",
    artist: "The Monkees",
    year: "1966",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "I'll Be There For You (Friends Theme)",
    artist: "The Rembrandts",
    year: "1995",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Teenage Kicks",
    artist: "The Undertones",
    year: "1978",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Lucky Man",
    artist: "The Verve",
    year: "1997",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Die A Happy Man",
    artist: "Thomas Rhett",
    year: "2015",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Fast Car",
    artist: "Tracy Chapman",
    year: "1988",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Happy Birthday",
    artist: "Traditional",
    year: "1893",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Hey Soul Sister",
    artist: "Train",
    year: "2009",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Brown Eyed Girl",
    artist: "Van Morrison",
    year: "1967",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Shut Up And Dance",
    artist: "Walk The Moon",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "9 to 5",
    artist: "Dolly Parton",
    year: "1980",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "A Hard Day's Night",
    artist: "The Beatles",
    year: "1964",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "A Thousand Years",
    artist: "Christina Perri",
    year: "2011",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "All The Small Things",
    artist: "Blink 182",
    year: "1999",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Amazing Grace",
    artist: "Traditional",
    year: "1779",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Annie's Song",
    artist: "John Denver",
    year: "1974",
    genres: ["Folk"],
    lyrics: "",
  },
  // {
  //   title: "Are You Gonna Be My Girl",
  //   artist: "Jet",
  //   year: "2003",
  //   genres: ["Rock"],
  //   lyrics: "",
  // },
  {
    title: "As It Was",
    artist: "Harry Styles",
    year: "2022",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Basket Case",
    artist: "Green Day",
    year: "1994",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Attention",
    artist: "Charlie Puth",
    year: "2017",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Auld Lang Syne",
    artist: "Traditional",
    year: "1788",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Be Young, Be Foolish, Be Happy",
    artist: "The Tams",
    year: "1968",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "I Would Walk 500 Miles",
    artist: "The Proclaimers",
    year: "1988",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Shook Me All Night Long",
    artist: "AC/DC",
    year: "1980",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Stand By Me",
    artist: "Ben E. King",
    year: "1961",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Twist And Shout",
    artist: "The Beatles",
    year: "1963",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Rhinestone Cowboy",
    artist: "Glen Campbell",
    year: "1975",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Jolene",
    artist: "Dolly Parton",
    year: "1973",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Sweet Home Alabama",
    artist: "Lynyrd Skynyrd",
    year: "1974",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Dirty Little Secret",
    artist: "The All-American Rejects",
    year: "2005",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Dancing Queen",
    artist: "ABBA",
    year: "1976",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "No Woman, No Cry",
    artist: "Bob Marley",
    year: "1974",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Three Little Birds",
    artist: "Bob Marley",
    year: "1977",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Don't You Want Me",
    artist: "The Human League",
    year: "1981",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Do You Remember",
    artist: "Jack Johnson",
    year: "2005",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Hold Back The River",
    artist: "James Bay",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Seven Nation Army",
    artist: "The White Stripes",
    year: "2003",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "I Bet You Look Good On The Dancefloor",
    artist: "Arctic Monkeys",
    year: "2005",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "When The Sun Goes Down",
    artist: "Arctic Monkeys",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Complicated",
    artist: "Avril Lavigne",
    year: "2002",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Blue Suede Shoes",
    artist: "Elvis Presley",
    year: "1956",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "The Middle",
    artist: "Jimmy Eat World",
    year: "2001",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Superstition",
    artist: "Stevie Wonder",
    year: "1972",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Uptown Funk",
    artist: "Bruno Mars",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Is This Love",
    artist: "Bob Marley",
    year: "1978",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Skinny Love",
    artist: "Bon Iver",
    year: "2007",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Count On Me",
    artist: "Bruno Mars",
    year: "2010",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Only Fools And Horses",
    artist: "John Sullivan",
    year: "1981",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Just The Way You Are",
    artist: "Bruno Mars",
    year: "2010",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Sky Full Of Stars",
    artist: "Coldplay",
    year: "2014",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Easy",
    artist: "Commodores",
    year: "1977",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Starman",
    artist: "David Bowie",
    year: "1972",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Iris",
    artist: "Goo Goo Dolls",
    year: "1998",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Leaving On A Jet Plane",
    artist: "John Denver",
    year: "1969",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Your Man",
    artist: "Josh Turner",
    year: "2005",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Your Song",
    artist: "Elton John",
    year: "1971",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Can You Feel The Love Tonight",
    artist: "Elton John",
    year: "1994",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Domino",
    artist: "Jessie J",
    year: "2011",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Heartache Medication",
    artist: "Jon Pardi",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Dani California",
    artist: "Red Hot Chili Peppers",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Every Breath You Take",
    artist: "The Police",
    year: "1983",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Sex On Fire",
    artist: "Kings Of Leon",
    year: "2008",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Ignition (Remix)",
    artist: "R. Kelly",
    year: "2003",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "She Will Be Loved",
    artist: "Maroon 5",
    year: "2002",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Layla",
    artist: "Eric Clapton",
    year: "1970",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Follow Your Arrow",
    artist: "Kacey Musgraves",
    year: "2013",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Jingle Bell Rock",
    artist: "Bobby Helms",
    year: "1957",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Driving Home For Christmas",
    artist: "Chris Rea",
    year: "1986",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Rockin' Around The Christmas Tree",
    artist: "Brenda Lee",
    year: "1958",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Merry Christmas Everyone",
    artist: "Shakin' Stevens",
    year: "1985",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Fairytale Of New York",
    artist: "The Pogues",
    year: "1987",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Feliz Navidad",
    artist: "José Feliciano",
    year: "1970",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Merry Xmas Everybody",
    artist: "Slade",
    year: "1973",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Santa Baby",
    artist: "Eartha Kitt",
    year: "1953",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Santa Claus Is Coming To Town",
    artist: "Bruce Springsteen",
    year: "1985",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "I Believe In Father Christmas",
    artist: "Greg Lake",
    year: "1975",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "It's The Most Wonderful Time Of The Year",
    artist: "Andy Williams",
    year: "1963",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Let It Snow! Let It Snow! Let It Snow!",
    artist: "Dean Martin",
    year: "1959",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Jingle Bells",
    artist: "Traditional",
    year: "1857",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "Winter Wonderland",
    artist: "Bing Crosby",
    year: "1942",
    genres: ["Christmas"],
    lyrics: "",
  },
  {
    title: "I See The Light",
    artist: "Tangled",
    year: "2010",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Love Is An Open Door",
    artist: "Frozen",
    year: "2013",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Do You Want To Build A Snowman?",
    artist: "Frozen",
    year: "2013",
    genres: ["Disney"],
    lyrics: "",
  },
  {
    title: "Soul To Squeeze",
    artist: "Red Hot Chili Peppers",
    year: "1993",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Snow (Hey Oh)",
    artist: "Red Hot Chili Peppers",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Come As You Are",
    artist: "Nirvana",
    year: "1991",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "20th Century Boy",
    artist: "T. Rex",
    year: "1973",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Girl All The Bad Guys Want",
    artist: "Bowling For Soup",
    year: "2002",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Come Fly With Me",
    artist: "Frank Sinatra",
    year: "1958",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Somebody Like You",
    artist: "Keith Urban",
    year: "2002",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Losing My Religion",
    artist: "R.E.M.",
    year: "1991",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Surfin' USA",
    artist: "The Beach Boys",
    year: "1963",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "I Feel Fine",
    artist: "The Beatles",
    year: "1964",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Hound Dog",
    artist: "Elvis Presley",
    year: "1956",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "One Man Band",
    artist: "Old Dominion",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Just A Day",
    artist: "Feeder",
    year: "2001",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "The Other Guy",
    artist: "Luke Combs",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Warmness On The Soul",
    artist: "Avenged Sevenfold",
    year: "2001",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "The One",
    artist: "Kodaline",
    year: "2015",
    genres: ["Folk"],
    lyrics: "",
  },
  {
    title: "Beer Never Broke My Heart",
    artist: "Luke Combs",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Mr. Lonely",
    artist: "Midland",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Chelsea Dagger",
    artist: "The Fratellis",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Beggin'",
    artist: "Madcon",
    year: "2007",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Proud Mary",
    artist: "Tina Turner",
    year: "1971",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Free Fallin'",
    artist: "Tom Petty",
    year: "1989",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Face Down",
    artist: "The Red Jumpsuit Apparatus",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "My Guardian Angel",
    artist: "The Red Jumpsuit Apparatus",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Hotel California",
    artist: "Eagles",
    year: "1976",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Ever Fallen In Love",
    artist: "Buzzcocks",
    year: "1978",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Lonely Boy",
    artist: "The Black Keys",
    year: "2011",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "History",
    artist: "One Direction",
    year: "2015",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "We Can Work It Out",
    artist: "The Beatles",
    year: "1965",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Drag Me Down",
    artist: "One Direction",
    year: "2015",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "Singles You Up",
    artist: "Jordan Davis",
    year: "2017",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "7500 OBO",
    artist: "Tim McGraw",
    year: "2020",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "More Then My Hometown",
    artist: "Morgan Wallen",
    year: "2020",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Mud On The Tires",
    artist: "Brad Paisley",
    year: "2003",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Rednecker",
    artist: "Hardy",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Love Ain't",
    artist: "Eli Young Band",
    year: "2018",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "When She Comes Home Tonight",
    artist: "Riley Green",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Long Live Cowgirls",
    artist: "Ian Munsick",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Til You Can't",
    artist: "Cody Johnson",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "All Over The Road",
    artist: "Easton Corbin",
    year: "2012",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "You Look Good",
    artist: "Lady A",
    year: "2017",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Guilty",
    artist: "The Shires",
    year: "2018",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "She Got The Best Of Me",
    artist: "Luke Combs",
    year: "2018",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Maybe Tomorrow",
    artist: "Stereophonics",
    year: "2003",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Love Me Do",
    artist: "The Beatles",
    year: "1962",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Monsters",
    artist: "Eric Church",
    year: "2019",
    genres: ["Country"],
    lyrics: "",
  },
  {
    title: "Songbird",
    artist: "Oasis",
    year: "2002",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Can't Stand Me Now",
    artist: "The Libertines",
    year: "2004",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "You're All I Have",
    artist: "Snow Patrol",
    year: "2006",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Have A Nice Day",
    artist: "Stereophonics",
    year: "2001",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Country House",
    artist: "Blur",
    year: "1995",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Dreaming Of You",
    artist: "The Coral",
    year: "2002",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Alright",
    artist: "Supergrass",
    year: "1995",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "The Drugs Don't Work",
    artist: "The Verve",
    year: "1997",
    genres: ["Rock"],
    lyrics: "",
  },
  {
    title: "Daydream Believer",
    artist: "The Monkees",
    year: "1967",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "This Old Heart Of Mine",
    artist: "The Isley Brothers",
    year: "1966",
    genres: ["Pop"],
    lyrics: "",
  },
  {
    title: "You Can't Hurry Love",
    artist: "The Supremes",
    year: "1966",
    genres: ["Pop"],
    lyrics: "",
  },
];
