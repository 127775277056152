import img1 from "./17-Brid Spa-111.jpg";
import img2 from "./23.jpg";
import img3 from "./293065817_489593256502302_323444016654407349_n.jpg";
import img4 from "./359477993_786786816782943_2422070276806942141_n.jpg";
import img5 from "./35.jpg";
import img6 from "./45.jpg";
import img7 from "./5-Brid Spa-17.jpg";
import img8 from "./77-Brid Spa-329.jpg";
import img9 from "./7-Brid Spa-24.jpg";
import img10 from "./97bd13_dd987ae869a3492f8a8e2c53eac52779mv2.jpg";
import img11 from "./97bd13_df838d09ffc74feaa31434290814ce58mv2.jpg";
import img12 from "./alisha1.jpg";
import img13 from "./alisha2.jpg";
import img14 from "./alisha3.jpg";
import img15 from "./amyAndAlex.jpg";
import img16 from "./FB_IMG_1601536165235.jpg";
import img17 from "./image_6483441.jpg";
import img18 from "./IMG_0575.jpg";
import img19 from "./IMG_1988[1].jpg";
import img20 from "./IMG_20180930_020214_999.jpg";
import img21 from "./IMG_20181025_171551_749.jpg";
import img22 from "./IMG_20181026_172834737.jpg";
import img23 from "./IMG_20181027_193119_899.jpg";
import img24 from "./IMG_20190601_192053.jpg";
import img25 from "./IMG_20190601_194707_380.jpg";
import img26 from "./IMG_20190613_174925.jpg";
import img27 from "./IMG_20190813_202750_686.jpg";
import img28 from "./IMG_20200127_154754_699.jpg";
import img29 from "./IMG_20200809_181556_480.jpg";
import img30 from "./IMG_20210709_111854_886.jpg";
import img31 from "./IMG_20210709_111854_894.jpg";
import img32 from "./IMG_20210709_111854_936.jpg";
import img33 from "./IMG-20210711-WA0004.jpg";
import img34 from "./IMG-20210711-WA0008.jpg";
import img35 from "./IMG_20210725_155644_421.jpg";
import img36 from "./IMG_20210802_011127_272.jpg";
import img37 from "./IMG_20210802_081319_903.jpg";
import img38 from "./IMG_20210802_081324_551.jpg";
import img39 from "./IMG_20210821_175520_511.jpg";
import img40 from "./IMG_20210905_145926_439.jpg";
import img41 from "./IMG_20210925_151817_993.jpg";
import img42 from "./IMG_20220525_163537_466.jpg";
import img43 from "./IMG_20220525_163537_538.jpg";
import img44 from "./IMG_20220528_143040_148.jpg";
import img45 from "./IMG_20220528_143040_167.jpg";
import img46 from "./IMG_20220618_122533_251.jpg";
import img47 from "./IMG_20220828_155148_817.jpg";
import img48 from "./IMG_20220905_211421_336.jpg";
import img49 from "./IMG_20221031_081759_970.jpg";
import img50 from "./IMG_20230506_114307_086.jpg";
import img51 from "./IMG_20230508_214339_824.jpg";
import img52 from "./IMG_20230606_160930_052.jpg";
import img53 from "./IMG_20230606_160930_121.jpg";
import img54 from "./IMG_20230716_191821_241.jpg";
import img55 from "./IMG_20230720_075112_493.jpg";
import img56 from "./IMG_20230720_075114_580.jpg";
import img57 from "./IMG_20230720_075118_142.jpg";
import img58 from "./IMG_20230720_075122_600.jpg";
import img59 from "./IMG_20230720_075124_829.jpg";
import img60 from "./IMG_2308.jpg";
import img61 from "./IMG_3404.jpg";
import img62 from "./IMG_3405.jpg";
import img63 from "./IMG_3406.jpg";
import img64 from "./IMG_4154.jpg";
import img65 from "./IMG_4155.jpg";
import img66 from "./IMG_4157.jpg";
import img67 from "./IMG_4158.jpg";
import img68 from "./JWF06735.00_01_12_08.Still006.jpg";
import img69 from "./_MG_7895.jpg";
import img70 from "./_MG_7896.jpg";
import img71 from "./_MG_7920.jpg";
import img72 from "./_MG_7930.jpg";
import img73 from "./_MG_7953.jpg";
import img74 from "./Print-1953.jpg";
import img75 from "./Print-1955.jpg";
import img76 from "./Print-481.jpg";
import img77 from "./Print-483.jpg";
import img78 from "./Print-485.jpg";
import img79 from "./Print-487.jpg";
import img80 from "./Print-489.jpg";
import img81 from "./PXL_20230701_144616464.MP.jpg";
import img82 from "./PXL_20230812_142243815.MP.jpg";
import img83 from "./received_238373995180779.jpg";
import img84 from "./received_282363499053625.jpg";
import img85 from "./received_667464248731417.jpg";
import img86 from "./sam.jpg";
import img87 from "./SD-19.jpg";
import img88 from "./SD-20.jpg";
import img89 from "./SD-21.jpg";
import img90 from "./SD-36.jpg";
import img91 from "./SD-3.jpg";
import img92 from "./SD-7.jpg";
import img93 from "./SD-8.jpg";
import img94 from "./SD-9.jpg";

const allPhotos: string[] = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
];

export default allPhotos;
