import { Song, playlistItem, playlistItemList } from "./appStateTypes";

export const emptySong: Song = {
  artist: "",
  title: "",
  year: "",
  genres: [],
  lyrics: "",
};

export const emptyPlaylistItem: playlistItem = {
  kind: "youtube#playlistItem",
  etag: "",
  id: "",
  snippet: {
    publishedAt: "",
    channelId: "",
    title: "",
    description: "",
    thumbnails: {
      default: {
        url: "",
        width: 0,
        height: 0,
      },
      medium: {
        url: "",
        width: 0,
        height: 0,
      },
      high: {
        url: "",
        width: 0,
        height: 0,
      },
      standard: {
        url: "",
        width: 0,
        height: 0,
      },
      maxres: {
        url: "",
        width: 0,
        height: 0,
      },
    },
    channelTitle: "",
    videoOwnerChannelTitle: "",
    videoOwnerChannelId: "",
    playlistId: "",
    position: 0,
    resourceId: {
      kind: "",
      videoId: "",
    },
  },
  contentDetails: {
    videoId: "",
    startAt: "",
    endAt: "",
    note: "",
    videoPublishedAt: "",
  },
  status: {
    privacyStatus: "",
  },
};

export const emptyPlaylistItemList: playlistItemList = {
  kind: "youtube#playlistItemListResponse",
  etag: "",
  nextPageToken: "",
  prevPageToken: "",
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0,
  },
  items: [emptyPlaylistItem],
};
