import { API } from "aws-amplify";

export async function getVideos() {
  const apiName = "samdawsonmusicAPI";
  const path = "/getVideos";
  const myInit = {
    headers: {}, // OPTIONAL
  };

  const response = await API.get(apiName, path, myInit);
  return response;
}
