import { Outlet } from "react-router";
import { Navbar } from "../NavBar";

export default function WithNav() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}
