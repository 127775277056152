import { useEffect, useState } from "react";
import { songs } from "../constants/songs";
import { decades, genres } from "../appConstants";
import { SongTile } from "./SongTile";
import { Song } from "../appStateTypes";

export const SongList = () => {
  const [filteredSongs, setFilteredSongs] = useState<Song[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [genreFilter, setGenreFilter] = useState<string>(genres.ALL_GENRES);
  const [decadeFilter, setDecadeFilter] = useState<string>(decades.ALL_DECADES);

  useEffect(() => {
    let filteredSongs = songs.filter(
      (song) =>
        song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (genreFilter !== genres.ALL_GENRES) {
      filteredSongs = filteredSongs.filter((song) =>
        song.genres.includes(genreFilter)
      );
    }
    if (decadeFilter !== decades.ALL_DECADES) {
      filteredSongs = filteredSongs.filter(
        (song) =>
          parseInt(song.year) >= parseInt(decadeFilter) &&
          parseInt(song.year) < parseInt(decadeFilter) + 10
      );
    }
    const sortedFilteredSongs = filteredSongs.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setFilteredSongs(sortedFilteredSongs);
  }, [searchTerm, genreFilter, decadeFilter]);

  const handleGenreChange = (genre: string) => {
    if (genreFilter === genre) {
      setGenreFilter(genres.ALL_GENRES);
    } else {
      setGenreFilter(genre);
    }
  };
  const handleDecadeChange = (decade: string) => {
    if (decadeFilter === decade) {
      setDecadeFilter(decades.ALL_DECADES);
    } else {
      setDecadeFilter(decade);
    }
  };
  return (
    <>
      <div className="row">
        <div className="input-group mb-3 mt-2">
          <span className="input-group-text">🔍</span>
          <input
            data-testid={`search-input`}
            type="text"
            className="form-control"
            placeholder="Search here"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <div className="d-flex mb-4">
          <div className="dropdown">
            <button
              data-testid={`selected-genre-button`}
              className="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {genreFilter}
            </button>
            <ul className="dropdown-menu">
              {Object.values(genres).map((genre) => (
                <li
                  id={`genre-button-${genre}`}
                  data-testid={`genre-button-${genre}`}
                  key={genre}
                  className={`dropdown-item`}
                  onClick={() => handleGenreChange(genre)}
                >
                  {genre}
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown px-2">
            <button
              data-testid={`selected-decade-button`}
              className="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {decadeFilter}
            </button>

            <ul className="dropdown-menu">
              {Object.values(decades).map((decade) => (
                <li
                  id={`decade-button-${decade}`}
                  data-testid={`decade-button-${decade}`}
                  key={decade}
                  className={`dropdown-item`}
                  onClick={() => handleDecadeChange(decade)}
                >
                  {decade}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ul className="list-group list-group-flush">
        {filteredSongs.map((song) => (
          <li className="list-group-item" key={song.title} onClick={() => {}}>
            <div className="row">
              <div className="col-12">
                <SongTile song={song} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
