import { useEffect, useState } from "react";
import { pageUrls, pages } from "../appConstants";

// Hides the navbar when scrolling down and shows it when scrolling up
export function useScrollDirection() {
  type directions = "up" | "down";
  const [scrollDirection, setScrollDirection] = useState<directions>("up");
  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 30 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

export const Navbar = () => {
  const scrollDirection = useScrollDirection();
  return (
    <nav
      className={`navbar navbar-expand-lg bg-body-tertiary shadow sticky-top header ${
        scrollDirection === "down" ? "hide" : "show"
      }`}
    >
      <div className="container container-fluid justify-content-between">
        <a className="navbar-brand" href={pageUrls.HOME}>
          Sam Dawson
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {pages.map((page, index) => (
              <li className="nav-item" key={index}>
                <a
                  className="nav-link active"
                  aria-current="page"
                  href={page.url}
                >
                  {page.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
