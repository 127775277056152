import { Song } from "../appStateTypes";

export type SongTileProps = {
  song: Song;
};

export const SongTile = (props: SongTileProps) => {
  return (
    <>
      <h5>{props.song.title}</h5>
      <p className="m-0">{props.song.artist}</p>
    </>
  );
};
