export const AboutPage = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col">
          <h1 className="text-center">About</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg d-none d-lg-flex flex-column justify-content-center">
          <img
            className="card shadow img-fluid"
            src={require("../images/about-photo.jpg").default}
            alt="Sam Dawson"
          />
        </div>
        <div className="col-lg">
          <p className="text-center">
            <b>
              Hi, my name is Sam Dawson and I’m a professional wedding singer
              and acoustic guitarist based in East Yorkshire.
            </b>
            <br></br>
            <br></br>
            My musical style is acoustic, ‘live-lounge’ covers of pop songs –
            anywhere from the fifties through to present day! <br></br>
            <br></br>Over the years I have performed at hundreds of weddings, be
            it during the ceremony, drinks reception, wedding breakfast, first
            dance… you name it, I’ve played it! <br></br>
            <br></br>Using just guitar, vocals, and the addition of some
            ‘effects pedals’, I am able to create a full sound – as though there
            are multiple musicians performing (without the huge setup and
            increased cost!) This also allows me to ‘read the room’ – stripping
            things back, or livening things up when necessary.
            <br></br> <br></br>
            On this site, you’ll find videos, reviews, my current song list, as
            well as details on how to contact me to discuss your event. If you
            have any questions, please don’t hesitate to send me a message.
            <br></br>
            <br></br> I look forward to hearing from you! 🙂
            <br></br>
            <br></br>
            <p className="signature ">Sam</p>
          </p>
        </div>
        {/* For Mobile */}
        <div className="col-lg d-flex d-lg-none flex-column justify-content-center">
          <img
            className="card shadow img-fluid"
            src={require("../images/about-photo.jpg").default}
            alt="Sam Dawson"
          />
        </div>
        <div className="col-lg text-center d-none d-lg-flex flex-lg-column justify-content-between">
          <h1>🙂</h1>
          <p>Confident Performer</p>
          <h1>⌚</h1>
          <p>Professional Attitude</p>
          <h1>🎼</h1>
          <p>350+ Songs</p>
          <h1>✅</h1>
          <p>PLI Certified / PAT Tested</p>
        </div>
      </div>
    </div>
  );
};
