import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { pageUrls } from "./appConstants";
import { AboutPage } from "./pages/AboutPage";
import { VideosPage } from "./pages/VideosPage";
import { ReviewsPage } from "./pages/ReviewsPage";
import { PhotosPage } from "./pages/PhotosPage";
import { ContactPage } from "./pages/ContactPage";
import { PackagesPage } from "./pages/PackagesPage";
import { SongListPage } from "./pages/SongListPage";
import WithoutNav from "./components/layouts/WithoutNav";
import WithNav from "./components/layouts/WithNav";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route element={<WithoutNav />}></Route>
          <Route element={<WithNav />}>
            {[pageUrls.ABOUT, pageUrls.HOME].map((path, index) => (
              <Route path={path} Component={AboutPage} key={index} />
            ))}
            <Route path={pageUrls.PACKAGES} Component={PackagesPage} />
            <Route path={pageUrls.VIDEOS} Component={VideosPage} />
            <Route path={pageUrls.REVIEWS} Component={ReviewsPage} />
            <Route path={pageUrls.PHOTOS} Component={PhotosPage} />
            <Route path={pageUrls.DYNAMICSONGLIST} Component={SongListPage} />
            <Route path={pageUrls.CONTACT} Component={ContactPage} />
          </Route>
        </Routes>
        {/* <Footer /> */}
        {/* </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
